<template>
  <div class="fill-width">
    <div class="background"></div>
    <v-container class="container">
      <v-card class="card-content">
        <v-card-text class="inherit-height">
          <v-row v-if="!$isMobile" class="inherit-height px-8">
            <v-col cols="12" sm="12" md="6" class="inherit-height">
              <div
                class="inherit-height d-flex flex-column justify-space-between"
              >
                <v-img
                  contain
                  src="@/assets/logotipo-programando-futuro-horizontal.svg"
                  alt="Logo Programando o Futuro"
                  max-height="100px"
                  max-width="300px"
                ></v-img>

                <div>
                  <h1>Desculpe,</h1>
                  <h2>página não encontrada.</h2>
                  <p>
                    Não conseguimos encontrar a página que você estava buscando.
                  </p>
                </div>

                <v-btn
                  @click="$router.back()"
                  :block="$isMobile"
                  color="secondary-color-1"
                  class="next-step-button white--text"
                  >Voltar</v-btn
                >
              </div>
            </v-col>

            <v-col cols="12" sm="12" md="6" class="inherit-height">
              <div class="d-flex justify-center align-center inherit-height">
                <v-img
                  contain
                  max-height="400px"
                  src="@/assets/robo-page-not-found.svg"
                ></v-img>
              </div>
            </v-col>
          </v-row>

          <v-row v-else class="inherit-height">
            <v-col cols="12" class="inherit-height">
              <div
                class="inherit-height d-flex flex-column justify-space-between align-center"
              >
                <v-img
                  class="pb-10"
                  contain
                  src="@/assets/logotipo-programando-futuro-horizontal.svg"
                  alt="Logo Programando o Futuro"
                  width="200px"
                ></v-img>

                <v-img
                  contain
                  max-height="200px"
                  src="@/assets/robo-page-not-found.svg"
                  alt="Robô Programando o Futuro"
                ></v-img>

                <h1 class="pt-5">Desculpe,</h1>

                <h2>página não encontrada.</h2>

                <p class="pa-0 pt-8">
                  Não conseguimos encontrar a
                </p>

                <p class="pa-0">
                  página que você estava buscando.
                </p>

                <div class="pt-8">
                  <v-btn
                    @click="$router.back()"
                    :block="$isMobile"
                    color="secondary-color-1"
                    class="next-step-button white--text"
                    >Voltar</v-btn
                  >
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  export default {
    name: "NotFound"
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .background {
    background: linear-gradient(to right, $primary-color-2, $primary-color-1);
    width: 100%;
    height: 332px;
    position: absolute;
    top: 0;
  }

  .container {
    padding-top: 80px;
    max-width: 1000px;

    .card-content {
      min-height: 450px;

      h1 {
        font-size: 37px;
        color: $black-1;
        font-weight: 700;
        padding: 10px 0;
      }
      h2 {
        color: $black-1;
        padding: 10px 0;
      }
      p {
        margin: 0;
        padding: 20px 0;
        max-width: 250px;
      }
    }
  }
</style>
